import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title } from '@components/common';
import GridContents from '@components/layout/GridContents/GridContents';
import Grid from '@components/layout/Grid/Grid';

const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    padding-bottom: 61px;
  `,
  CardGroup: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  Card: styled.div`
    width: 100%;
    height: 328px;
    background-image: linear-gradient(lightgray 172px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0),
      linear-gradient(lightgray 16px, transparent 0);
    background-repeat: repeat-y;
    background-size: 100% 328px, 180px 304px, 90px 304px, 32px 304px;
    background-position: 0px 0px, 0px 188px, 188px 214px, 0px 214px;
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  `,
};

export default function CardGroupSkeleton() {
  return (
    <GridContents marginTop={80}>
      <Grid.Item>
        <Title isSkeleton={true} isView={true} style={{ width: '100%' }} />
      </Grid.Item>
      <Grid.Item key={1} xl={3} lg={6} md={4} sm={4}>
        <Styled.Card />
      </Grid.Item>
      <Grid.Item key={2} xl={3} lg={6} md={4} sm={4}>
        <Styled.Card />
      </Grid.Item>
      <Grid.Item key={3} xl={3} lg={6} md={4} sm={4}>
        <Styled.Card />
      </Grid.Item>
      <Grid.Item key={4} xl={3} lg={6} md={4} sm={4}>
        <Styled.Card />
      </Grid.Item>
      {/* <Grid.Item>
        <Styled.Wrapper>
          <Styled.CardGroup>
            <Styled.Card />
            <Styled.Card />
            <Styled.Card />
          </Styled.CardGroup>
        </Styled.Wrapper>
      </Grid.Item> */}
    </GridContents>
  );
}
