import styled from 'styled-components';
import Grid from '@components/layout/Grid/Grid';
import GridContents from '@components/layout/GridContents/GridContents';

export const CardGroupContainer = styled(GridContents)``;

export const CardGroupItemContainer = styled(Grid)`
  padding: 0;
  width: 100%;
`;
