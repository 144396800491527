import React from 'react';
import Link from 'next/link';
import { noop } from 'react-use/lib/misc/util';
import { FlexColumnAlignCenter } from '@components/wrapper';
import { CustomImage } from '@components/ui';

type Props = {
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
  linkUrl?: string;
  backgroundColor?: string;
  style?: any;
  target?: string;
  title?: string;
  className?: string;
  onClick?: (event: React.SyntheticEvent) => void;
};

export const ImageBanner: React.FC<Props> = ({ imageUrl, linkUrl, imageWidth, imageHeight, backgroundColor, style, target, title = 'banner', className = '', onClick = noop }) => {
  return (
    <FlexColumnAlignCenter
      style={{
        backgroundColor: backgroundColor,
        height: imageHeight,
        ...style,
      }}
      className={className}
    >
      {linkUrl ? (
        <Link href={linkUrl} prefetch={false} target={target} rel="noreferrer">
          <CustomImage src={imageUrl} alt={title} width={imageWidth} height={imageHeight} objectFit={'fill'} placeholder={'empty'} />
        </Link>
      ) : (
        <CustomImage src={imageUrl} alt={title} width={imageWidth} height={imageHeight} objectFit={'fill'} placeholder={'empty'} onClick={onClick} />
      )}
    </FlexColumnAlignCenter>
  );
};
