import React, { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import mainCardList from 'public/mainCardList.json';
import { BANNER_INTERNAL_NAME, BEST_INTERNAL_NAME, FIXED_BANNER_INTERNAL_NAME, GOODS_INTERNAL_NAME, HOT_INTERNAL_NAME } from 'src/constants';
import { ImageBanner } from '@components/common/ImageBanner';
import { convertLangCodeForDB } from '@lib';
import { AdminPickCardGroup, SnoCardGroup } from '@components/common/CardGroupGrid';
import HotModelCardGroup from '@components/common/CardGroupGrid/HotModelCardGroup';
import { useMainProductListV2Query } from '../../../src/generated/graphql';
import { SlideGroup, SlideResponsiveGroup, Wrapper } from './styled';

export interface IResponsiveSno {
  mobile: number;
  tablet: number;
  desktop: number;
}

type Widget = {
  sno?: number | IResponsiveSno;
  type: 'goods' | 'banner' | 'fixedBanner' | 'best' | 'hot';
  linkUrl?: string;
  imageUrl?: string;
  isDisplayRankBadge?: boolean;
};

export const MainWidget = (): React.ReactElement => {
  const { i18n, ready } = useTranslation();

  // 주간 베스트 타이틀 가져와야 해서...
  const { data, loading } = useMainProductListV2Query({
    variables: {
      lang: convertLangCodeForDB(i18n.language),
      sno: 24,
    },
  });
  if (!ready || loading) return <div></div>;
  const AdminPickTitle = data?.mainProductListV2?.title || '';

  return (
    <Wrapper>
      {
        // JSON 데이터 기반으로 메인 위젯을 리턴
        (mainCardList[i18n.language] ?? []).map((cardInfo: Widget, idx: number) => {
          switch (cardInfo && cardInfo.type) {
            case HOT_INTERNAL_NAME:
              return <HotModelCardGroup key={idx} isDisplayRankBadge={cardInfo.isDisplayRankBadge} eventPageName={'main'} />;
            case GOODS_INTERNAL_NAME:
              return <SnoCardGroup key={idx} sno={Number(cardInfo.sno)} isDisplayRankBadge={cardInfo.isDisplayRankBadge} eventPageName={'main'} />;
            case BANNER_INTERNAL_NAME: {
              const isResponsive = Boolean(typeof cardInfo.sno === 'number');
              if (isResponsive) return <SlideGroup key={idx} bannerCode={Number(cardInfo.sno)} />;
              const { mobile, tablet, desktop } = cardInfo.sno as IResponsiveSno;
              return (
                <Fragment key={idx}>
                  <Box sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                    <SlideResponsiveGroup bannerCode={desktop} isBorder={false} />
                  </Box>
                  <Box sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'none' } }}>
                    <SlideResponsiveGroup bannerCode={tablet} isBorder={false} />
                  </Box>
                  <Box sx={{ display: { lg: 'none', md: 'none', sm: 'none', xs: 'block' } }}>
                    <SlideResponsiveGroup bannerCode={mobile} isBorder={false} />
                  </Box>
                </Fragment>
              );
            }
            case FIXED_BANNER_INTERNAL_NAME:
              return (
                <div key={idx} data-mixpanel-action="click" data-mixpanel-evt="Click Banner" data-mixpanel-type="Fixed Banner" data-mixpanel-link={cardInfo.linkUrl}>
                  <ImageBanner imageUrl={cardInfo.imageUrl} linkUrl={cardInfo.linkUrl} imageHeight={120} imageWidth={900} style={{ marginBottom: '90px' }} />
                </div>
              );
            case BEST_INTERNAL_NAME:
              return <AdminPickCardGroup key={idx} title={AdminPickTitle} isDisplayRankBadge={cardInfo.isDisplayRankBadge} />;
          }
          return null;
        })
      }
    </Wrapper>
  );
};
